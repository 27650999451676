import MicrosoftAuthenticationProvider from "./MicrosoftDriveAuthenticationProvider";
import { Client, ClientOptions } from "@microsoft/microsoft-graph-client";
import { DriveItem, NullableOption } from "@microsoft/microsoft-graph-types";
import { CollectionName } from "../../../accurasee-backend-types/app/general/collection.types";
import { MicrosoftDriveConfig } from "../../../accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";
import { PublicClientApplication } from "@azure/msal-browser";
import { combine, getTemplatePath } from "../Utils/PathHelper";
import localConfig from "src/local-config";
import { ContractOfferPopulated } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import MicrosoftDocTemplateProcessor from "./MicrosoftDocTemplateProcessor/MicrosoftDocTemplateProcessor";
import { TFunction } from "../../../hooks/useTranslationWrapper";
// import generateDocument from "./MicrosoftDocTemplateProcessor/TemplateGenerator";
// import templateTagsContractOffer from "../Data/templateTagsContractOffer";
const PizZipUtils = require("pizzip/utils/index.js");

var mime = require("mime-types");

var openAppTimeout: any;
window.addEventListener("blur", function (e) {
  if (openAppTimeout) {
    window.clearTimeout(openAppTimeout);
  }
});

export type MicrosoftTemplatePopulateData = {
  type: "contract_offer";
  contractOfferPopulated?: ContractOfferPopulated;
  user?: ReturnUser;
};

export type MicrosoftDrivePickerFile = {
  "@sharePoint.embedUrl": string;
  "@sharePoint.endpoint": string;
  "@sharePoint.listUrl": string;
  folder?: string;
  id: string;
  name: string;
  parentReference: {
    driveId: string;
  };
  photo: {
    cameraMake?: string;
    cameraModel?: string;
    fNumber?: number;
    exposureDenominator?: number;
    exposureNumerator?: number;
    // Add other properties as needed
  };
  sharepointIds: {
    listItemUniqueId: string;
    listItemId: string;
    listId: string;
    webId: string;
    siteId: string;
    // Add other properties as needed
  };
  size: number;
  webDavUrl: string;
  webUrl: string;
};

export type MicrosoftDriveSavedLocation = {
  remoteItem?: {
    parentReference?: {
      driveId: string;
    };
    id: string;
  };
  parentReference?: {
    driveId: string;
  };
  id: string;
};

type MicrosoftDriveServiceProps = {
  config: MicrosoftDriveConfig;
  t: TFunction;
  setIsLocked: (v: boolean) => void;
};

export type MicrosoftDrivePopulateTemplateData<
  DocumentType extends {},
  ExtraType extends {},
> = {
  document: Partial<DocumentType>;
  extra?: ExtraType;
};

export type MicrosoftItemReference = {
  driveId: "string";
  driveType: "personal | business | documentLibrary";
  id: "string";
  listId: "string";
  name: "string";
  path: "string";
  shareId: "string";
  sharepointIds: { "@odata.type": "microsoft.graph.sharepointIds" };
  siteId: "string";
};

export class MicrosoftDriveService {
  private app: PublicClientApplication;
  private authenProvider: MicrosoftAuthenticationProvider | undefined;
  private currentPopupWindow: Window | null;
  private currentPort: MessagePort | undefined;
  private graphClient: Client | undefined;
  public rootDriveId: string;
  public rootItemId: string;
  public t: TFunction;
  public setIsLocked: (v: boolean) => void;

  public config: MicrosoftDriveConfig;

  constructor({ config, t, setIsLocked }: MicrosoftDriveServiceProps) {
    this.config = config;

    const msalParams = {
      auth: {
        authority: "https://login.microsoftonline.com/common",
        clientId: localConfig.azureClientId,
        redirectUri: window.origin,
      },
    };

    this.currentPopupWindow = null;
    this.rootDriveId = "";
    this.rootItemId = "";
    this.setIsLocked = setIsLocked;
    this.t = t;
    this.app = new PublicClientApplication(msalParams);
    this.initializeApp();
  }

  async getRootDriveId() {
    if (!this.rootDriveId) {
      await this.initializeApp();
    }
    return this.rootDriveId;
  }

  async initializeApp() {
    try {
      // await this.app.initialize();
      this.authenProvider = new MicrosoftAuthenticationProvider(this.app);
      let clientOptions: ClientOptions = {
        authProvider: this.authenProvider,
      };
      this.graphClient = Client.initWithMiddleware(clientOptions);

      const savedLocation = await this.getSaveLocation();

      this.rootDriveId =
        savedLocation.remoteItem?.parentReference?.driveId ||
        savedLocation.parentReference?.driveId ||
        "";
      this.rootItemId = savedLocation.remoteItem?.id || savedLocation.id || "";
    } catch (error) {
      console.error("Error initializing PublicClientApplication:", error);
    }
  }

  async addDocumentToTemplate({
    template,
    data,
    fileName,
    projectPath,
  }: {
    template: any;
    data?: MicrosoftTemplatePopulateData;
    fileName: string;
    projectPath: string;
  }) {
    //1 - download the file
    //2 - gather needed information
    //3 - populate project info
    //4 - get save location info
    //5 - upload document to save location
    //6 - open to edit

    if (!data) return Promise.reject(new Error("Data is not provided"));

    try {
      const fileData = await this.downloadFile(template);
      const tempPath = fileData["@microsoft.graph.downloadUrl"];

      const content = await new Promise<string>((resolve, reject) => {
        PizZipUtils.getBinaryContent(
          tempPath,
          (error: Error | null, content: string) => {
            if (error) {
              reject(error);
            } else {
              resolve(content);
            }
          },
        );
      });

      const contentType = mime.contentType(template.name);
      const templateProcessor = new MicrosoftDocTemplateProcessor({
        content,
        contentType,
        data,
        template: this.config.template,
      });

      const result = await templateProcessor.generateDocument();

      const generatedDocument = await this.uploadFile(
        fileName,
        result,
        projectPath,
      );

      return generatedDocument;
    } catch (e) {
      throw e;
    }
  }

  async addMetadata({ id, metadata }: { id?: string; metadata: any }) {
    if (!this.graphClient) throw new Error("Graph client is not initialized");
    if (!id) throw new Error("Id is not provided");

    try {
      return await this.graphClient
        .api(`/drives/${await this.getRootDriveId()}/items/${id}`)
        .patch(metadata)
        .then((v) => {
          this.setIsLocked(false);
          return v;
        });
    } catch (e: any) {
      if (e.message === "The resource you are attempting to access is locked") {
        this.setIsLocked(true);
      }
    }
  }

  async copyContractOfferToProject({
    contractOfferExternalId,
    projectExternalId,
  }: {
    contractOfferExternalId: string;
    projectExternalId?: string;
  }) {
    if (projectExternalId) {
      const sourcePath = this.getProjectPath({
        collectionName: "contract_offers",
        leafFolder: contractOfferExternalId,
      });

      const targetPath = this.getProjectPath({
        collectionName: "contracts",
      });

      if (!(await this.exist(targetPath))) {
        const path = targetPath.split("/").slice(0, -1).join("/");
        const newLeafFolderName = targetPath.split("/").pop();

        await this.createFolder({
          newLeafFolderName: newLeafFolderName,
          sourcePath: path,
        });
      }

      return this?.copyFolder({
        sourcePath,
        targetPath,
        newLeafFolderName: projectExternalId,
      });
    } else {
      throw new Error("Project external id is not provided");
    }
  }

  async copyFilesCloneContractOffer({
    contractOfferExternalId,
    contractOfferCloneExternalId,
    fileNames,
  }: {
    contractOfferExternalId: string;
    contractOfferCloneExternalId: string;
    fileNames: string[];
  }) {
    const sourcePath = this.getProjectPath({
      collectionName: "contract_offers",
      leafFolder: contractOfferExternalId,
    });

    const targetPath = this.getProjectPath({
      collectionName: "contract_offers",
      leafFolder: contractOfferCloneExternalId,
    });

    if (!(await this.exist(targetPath))) {
      const path = targetPath.split("/").slice(0, -1).join("/");
      const newLeafFolderName = targetPath.split("/").pop();

      await this.createFolder({
        newLeafFolderName: newLeafFolderName,
        sourcePath: path,
      });
    }

    return this.copyFiles({
      fileNames: fileNames,
      sourcePath,
      targetPath,
    });
  }

  async copyFiles({
    fileNames,
    sourcePath,
    targetPath,
  }: {
    fileNames?: string[];
    sourcePath: string;
    targetPath: string;
  }): Promise<any> {
    if (!fileNames) {
      return;
    }

    if (this.graphClient) {
      for (const fileName of fileNames) {
        const folderDriveItemSource = await this.getDriveItemFromPath(
          `${sourcePath}/${fileName}`,
        );

        // Get the target folder drive item without the file name
        const folderDriveItemTarget =
          await this.getDriveItemFromPath(targetPath);

        await this.graphClient
          .api(
            `/drives/${await this.getRootDriveId()}/items/${folderDriveItemSource.id}/copy`,
          )
          .post({
            parentReference: {
              driveId: await this.getRootDriveId(),
              id: folderDriveItemTarget.id,
            },
            // Specify the file name in the target folder if needed
            name: fileName,
          });
      }
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  async copyFolder({
    newLeafFolderName,
    sourcePath,
    targetPath,
  }: {
    newLeafFolderName?: string;
    sourcePath: string;
    targetPath: string;
  }): Promise<any> {
    if (this.graphClient) {
      const folderDriveItemSource = await this.getDriveItemFromPath(sourcePath);
      const folderDriveItemTarget = await this.getDriveItemFromPath(targetPath);

      return this.graphClient
        .api(
          `/drives/${await this.getRootDriveId()}/items/${folderDriveItemSource.id}/copy`,
        )
        .post({
          ...(folderDriveItemTarget
            ? {
                parentReference: {
                  driveId: await this.getRootDriveId(),
                  id: folderDriveItemTarget.id,
                },
              }
            : {}),
          ...(newLeafFolderName ? { name: newLeafFolderName } : {}),
        });
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  async createFolder({
    newLeafFolderName,
    sourcePath,
  }: {
    newLeafFolderName?: string;
    sourcePath: string;
  }): Promise<any> {
    if (this.graphClient) {
      if (!(await this.exist(sourcePath))) {
        const path = sourcePath.split("/").slice(0, -1).join("/");
        const newLeafFolderName = sourcePath.split("/").pop();

        await this.createFolder({
          newLeafFolderName: newLeafFolderName,
          sourcePath: path,
        });
      }

      const folderDriveItemSource = await this.getDriveItemFromPath(sourcePath);

      const path = `${sourcePath}/${newLeafFolderName}`;

      if (await this.exist(path)) {
        return "exist";
      }

      try {
        return this.graphClient
          .api(
            `/drives/${await this.getRootDriveId()}/items/${folderDriveItemSource.id}/children`,
          )
          .post({
            name: newLeafFolderName,
            folder: {},
          });
      } catch (e) {
        throw e;
      }
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  async exist(path: string) {
    try {
      await this.getDriveItemFromPath(path);
      return true;
    } catch (e: any) {
      if (e.message.includes("The resource could not be found")) {
        return false;
      } else {
        throw e;
      }
    }
  }

  downloadFile(file: any): Promise<string> {
    const itemId = file.id;
    if (!this.graphClient) throw new Error("Graph client is not initialized");
    return this.graphClient
      .api(`/drives/${file.parentReference.driveId}/items/${itemId}`)
      .select("@microsoft.graph.downloadUrl")
      .get();
  }

  async downloadFileWithFetch(file: any): Promise<Blob> {
    const data = await this.downloadFile(file);

    const response = await fetch(data["@microsoft.graph.downloadUrl"]);

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file as Blob
    const fileBlob = await response.blob();

    return fileBlob;
  }

  downloadFileContent(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const itemId = file.id;
      if (this.graphClient) {
        return this.graphClient
          .api(`/drives/${file.parentReference.driveId}/items/${itemId}`)
          .select("@microsoft.graph.downloadUrl")
          .get()
          .then((fileData: any) => {
            const tempPath = fileData["@microsoft.graph.downloadUrl"];
            PizZipUtils.getBinaryContent(
              tempPath,
              (error: Error | null, content: string) => {
                if (error) {
                  reject(error);
                  return;
                }
                resolve(content);
              },
            );
          })
          .catch((e: Error) => {
            reject(e);
          });
      } else {
        reject(new Error("Graph client is not initialized"));
      }
    });
  }

  getProjectPath({
    collectionName,
    leafFolder,
  }: {
    collectionName: CollectionName;
    leafFolder?: string;
  }) {
    let path = "";
    switch (collectionName) {
      case "contracts":
        path = "Project documents";
        break;
      case "contract_offers":
        path = "Contract offers documents";
        break;
    }

    return combine([
      this.config.storageSaveLocation,
      path,
      ...(leafFolder ? [leafFolder] : []),
    ]);
  }

  getSaveLocation(): Promise<MicrosoftDriveSavedLocation> {
    const url = this.config.storageSharedSaveLocationUrl;
    const path = this.config.storageSaveLocation;

    let normalizePath = !path.startsWith("/") ? `/${path}` : path;
    normalizePath = normalizePath.endsWith("/")
      ? normalizePath.slice(0, -1)
      : normalizePath;
    if (this.graphClient) {
      if (!url) {
        return this.graphClient.api(`/me/drive/root:${normalizePath}`).get();
      }

      return new Promise((resolve, reject) => {
        // if url is pass, it's shared location
        const regex = /\/sites\/*/;
        const isSite = regex.test(url);

        if (this.graphClient) {
          if (isSite) {
            const urlParams = new URL(url);
            const hostName = urlParams.hostname;
            const siteName = urlParams.pathname.split("/").pop();
            //get site id
            this.graphClient
              .api(`/sites/${hostName}:/sites/${siteName}`)
              .get()
              .then((siteInfo: any) => {
                const siteId = siteInfo.id;
                // get drive id
                if (this.graphClient) {
                  this.graphClient
                    .api(`/sites/${siteId}/drive/root:${normalizePath}`)
                    .get()
                    .then((driveItem: any) => {
                      resolve(driveItem);
                    })
                    .catch((e: any) => {
                      reject(e);
                    });
                }
              })
              .catch((e: any) => {
                reject(e);
              });
          } else {
            Promise.all([
              this.graphClient.api("/me/drive/sharedWithMe").get(),
              this.graphClient.api("/me/drive").get(),
            ])
              .then(([sharedData, myData]: any) => {
                if (myData.webUrl.startsWith(url)) {
                  if (this.graphClient) {
                    this.graphClient
                      .api(`/me/drive/root:${normalizePath}`)
                      .get()
                      .then((data: any) => {
                        resolve(data);
                      })
                      .catch((e: any) => {
                        reject(e);
                      });
                  }
                } else {
                  const allSharedFolders = sharedData.value;
                  const sharedFolder = allSharedFolders.find(
                    (e: any) => e.webUrl.startsWith(url) && e.name === path,
                  );
                  if (sharedFolder) {
                    resolve(sharedFolder);
                  } else {
                    reject(new Error("Shared folder not found"));
                  }
                }
              })
              .catch((e: any) => {
                reject(e);
              });
          }
        }
      });
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  async getDriveItemFromPath(path: string): Promise<DriveItem> {
    if (this.graphClient) {
      try {
        const res = await this.graphClient
          .api(`/drives/${await this.getRootDriveId()}/root:/${path}`)
          .get();

        return res as DriveItem;
      } catch (e) {
        throw e;
      }
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  async getFiles(path: string) {
    const driveId = await this.getRootDriveId();

    if (this.graphClient) {
      return driveId
        ? this.graphClient
            .api(`/drives/${driveId}/root:/${path}:/children`)
            .select(
              "id,name,remoteItem,parentReference,file,createdBy,createdDateTime,description,webUrl,webDavUrl,folder",
            )
            .get()
        : [];
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  getFileMetadata({
    driveId,
    id,
  }: {
    driveId?: NullableOption<string>;
    id?: string;
  }): Promise<any> {
    if (!this.graphClient) throw new Error("Graph client is not initialized");
    if (!driveId) throw new Error("Drive id is not provided");
    if (!id) throw new Error("Item id is not provided");

    return this.graphClient
      .api(`/drives/${driveId}/items/${id}`)
      .select(
        "id,name,remoteItem,parentReference,file,createdBy,createdDateTime,description,webUrl,webDavUrl,folder",
      )
      .get() as Promise<
      Pick<
        DriveItem,
        | "id"
        | "name"
        | "remoteItem"
        | "parentReference"
        | "file"
        | "createdBy"
        | "createdDateTime"
        | "description"
        | "webUrl"
        | "webDavUrl"
        | "folder"
      >
    >;
  }

  messageListener(message: MessageEvent, callback: any) {
    if (!this.authenProvider)
      throw new Error("Authen provider is not initialized");

    switch (message.data.type) {
      case "notification":
        console.log(`notification: ${message.data}`);
        break;

      case "command":
        this.currentPort?.postMessage({
          type: "acknowledge",
          id: message.data.id,
        });

        const command = message.data.data;

        switch (command.command) {
          case "authenticate":
            this.authenProvider.getToken(command).then((token) => {
              if (typeof token !== "undefined" && token !== null) {
                this.currentPort?.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "token",
                    token,
                  },
                });
              } else {
                console.error(
                  `Could not get auth token for command: ${JSON.stringify(
                    command,
                  )}`,
                );
              }
            });
            break;

          case "close":
            this.currentPopupWindow?.close();
            break;

          case "pick":
            this.currentPort?.postMessage({
              type: "result",
              id: message.data.id,
              data: {
                result: "success",
              },
            });
            if (callback) {
              callback(command.items, null);
            }
            this.currentPopupWindow?.close();

            break;

          default:
            console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);

            this.currentPort?.postMessage({
              result: "error",
              error: {
                code: "unsupportedCommand",
                message: command.command,
              },
              isExpected: true,
            });
            break;
        }

        break;
    }
  }

  openPicker(
    callback: (files: MicrosoftDrivePickerFile[], error: Error) => void,
  ) {
    const config = this.config;
    const { storageUrl, storageType } = config || {};
    const baseUrl = storageUrl || "https://onedrive.live.com/picker";
    const entry =
      storageType === "sharepoint" && config.storageTemplateLocation
        ? {
            sharePoint: {
              byPath: {
                web: config.storageSharedTemplateUrl,
                folder: getTemplatePath(config),
              },
            },
          }
        : {
            oneDrive: {
              file: {},
            },
          };
    const params = {
      sdk: "8.0",
      entry,
      authentication: {},
      messaging: {
        origin: window.origin,
        channelId: "27",
      },
      typesAndSources: {
        mode: "files",
        pivots: {
          oneDrive: true,
          recent: true,
        },
      },
    };
    if (this.currentPopupWindow && !this.currentPopupWindow.closed) {
      this.currentPopupWindow.close();
    }
    this.currentPopupWindow = window.open("", "Picker", "width=800,height=600");

    if (!this.authenProvider)
      throw new Error("Authen provider is not initialized");

    this.authenProvider
      .getToken({
        resource: baseUrl,
        command: "authenticate",
        type: "SharePoint",
      })
      .then((authToken) => {
        const queryString = new URLSearchParams({
          filePicker: JSON.stringify(params),
        });

        if (!this.authenProvider)
          throw new Error("Authen provider is not initialized");

        const url = this.authenProvider.combine(
          baseUrl,
          `_layouts/15/FilePicker.aspx?${queryString}`,
        );

        if (this.currentPopupWindow) {
          const form = this.currentPopupWindow.document.createElement("form");
          form.setAttribute("action", url);
          form.setAttribute("method", "POST");
          this.currentPopupWindow.document.body.append(form);

          const input = this.currentPopupWindow.document.createElement("input");
          input.setAttribute("type", "hidden");
          input.setAttribute("name", "access_token");
          input.setAttribute("value", authToken);
          form.appendChild(input);

          form.submit();
        }

        window.addEventListener("message", (event) => {
          if (event.source && event.source === this.currentPopupWindow) {
            const message = event.data;

            if (
              message.type === "initialize" &&
              message.channelId === params.messaging.channelId
            ) {
              this.currentPort = event.ports[0];

              this.currentPort.addEventListener("message", (message: any) => {
                this.messageListener(message, callback);
              });

              this.currentPort.start();

              this.currentPort.postMessage({
                type: "activate",
              });
            }
          }
        });
      })
      .catch((e) => console.error(e));
  }
  async uploadFile(fileName: string, binary: any, projectPath: string) {
    if (this.graphClient) {
      return this.graphClient
        .api(
          `/drives/${await this.getRootDriveId()}/root:/${projectPath}/${fileName}:/content`,
        )
        .put(binary) as Promise<Omit<DriveItem, "webDavUrl">>;
    } else {
      throw new Error("Graph client is not initialized");
    }
  }

  sendMail({
    emails,
    subject,
    body,
    attachments,
  }: {
    emails: any[];
    subject: string;
    body: string;
    attachments: any[];
  }) {
    const mail = {
      subject: subject,
      toRecipients: emails.map((mail) => {
        return { emailAddress: { address: mail } };
      }),
      body: {
        content: body,
        contentType: "html",
      },
      attachments,
    };

    if (this.graphClient) {
      return this.graphClient
        .api("/me/sendMail")
        .post({ message: mail, saveToSentItems: true });
    }
  }

  openURL(
    url: string,
    fallbackURL: string,
    fileName: string,
    document?: any,
  ): Promise<any> {
    return new Promise((resolve) => {
      const contentType = mime.contentType(fileName);
      if (
        contentType === "application/vnd.ms-excel" ||
        contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this._openURL(`ms-excel:ofe|u|${url}`, fallbackURL);
        resolve({});
      } else if (
        contentType === "application/msword" ||
        contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        this._openURL(`ms-word:ofe|u|${url}`, fallbackURL);
        resolve({});
      } else if (contentType === "application/vnd.ms-outlook") {
        this._openMail(document).then(resolve);
      } else {
        window.open(fallbackURL);
        resolve({});
      }
    });
  }

  _openURL(url: string, fallbackURL: string) {
    // Check if the operating system is Linux
    if (navigator.platform.indexOf("Linux") !== -1) {
      window.open(fallbackURL);
    } else {
      window.location.href = url;
      openAppTimeout = setTimeout(function () {
        window.open(fallbackURL);
      }, 1000);
    }
  }

  _openMail(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.downloadFileContent(file).then((content: any) => {
        const attachments = [
          {
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: file["name"],
            contentType: file["file"].mimeType,
            contentBytes: this.arrayBufferToBase64(content),
          },
        ];

        const mail = {
          attachments,
        };

        if (this.graphClient) {
          this.graphClient
            .api("/me/messages")
            .post(mail)
            .then((mailData: any) => {
              resolve({});
              window.open(mailData.webLink);
            });
        }
      });
    });
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
