import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonGroup from "../../Simple/Buttons/ButtonGroup";
import ButtonMicrosoftDriveCreateFileFromTemplate from "../Buttons/ButtonMicrosoftDriveCreateFileFromTemplate";
import ButtonMicrosoftDriveFileUpload from "../Buttons/ButtonMicrosoftDriveFileUpload";
import ButtonSecondary from "../../Simple/Buttons/ButtonSecondary";
import getMissingDataMessage from "src/utils/getMissingDataMessage";
import MessageBase from "../../Simple/Messages/MessageBase";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import useFormTable from "src/hooks/useFormTable";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Grid } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { MicrosoftDriveContext } from "src/context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import { MicrosoftTemplatePopulateData } from "src/context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { useContext, useEffect, useState } from "react";
import { useGetCompanyConfigQuery } from "src/redux/services/CompanyConfigsService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  getStructure,
  GetStructureExtraProps,
} from "./MicrosoftDriveTableStructure";
import useMicrosoftDriveList, {
  MicrosoftDriveData,
} from "src/hooks/useMicrosoftDrive/useMicrosoftDriveList";
import PromptDialog from "../../Simple/Dialogs/PromptDialog";

type MicrosoftDriveTableTProps = {
  collectionName: "contract_offers" | "contracts";
  dataPopulate?: MicrosoftTemplatePopulateData;
  label?: string;
  leafFolder: string;
  onlyTable?: boolean;
  prefixDocumentName: string;
  setSelectedRows?: (rows: MicrosoftDriveData[]) => void;
  showTemplateButton?: boolean;
};

const MicrosoftDriveTable = (props: MicrosoftDriveTableTProps) => {
  const [t] = useTranslation();
  // Add state for selected row index
  const [selectedRowsIndex, setSelectedRowsIndex] = useState<number[]>([]);

  const leafFolder = props.leafFolder; //getContractOfferExternalId(props?.contractOffer, true);

  const {
    isMicrosoftDriveClientLocked,
    microsoftDriveClient,
    setIsMicrosoftDriveClientLocked,
  } = useContext(MicrosoftDriveContext);

  const { data, isLoading, isError, error, invalidate } = useMicrosoftDriveList(
    {
      collectionName: props.collectionName,
      leafFolder,
      service: microsoftDriveClient,
    },
  );

  // Ensure that the table is updated when the leafFolder changes
  useEffect(() => {
    invalidate();
  }, [props.leafFolder]);

  const { data: companyConfigsQuery, isLoading: isLoadingCompanyConfigsQuery } =
    useGetCompanyConfigQuery(undefined);
  const { groups, types } = companyConfigsQuery?.data?.drive?.config || {};

  const { formData, helperText, structure } = useFormTable<
    MicrosoftDriveData,
    GetStructureExtraProps
  >({
    extraProps: { invalidate, service: microsoftDriveClient, groups, types },
    initialFormData: data,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<MicrosoftDriveData>({
      data: formData,
      item,
      helperText,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 5,
    defaultPageSize: 5,
    defaultPageSizeOptions: [5, 10, 20],
    ...(props.setSelectedRows
      ? {
          filterOptions: {
            selectableRows: "multiple",
            selectFunction: (_, current) => {
              if (props.setSelectedRows) {
                const rows = current.map((c) => formData[c.dataIndex]);
                props.setSelectedRows(rows);
                setSelectedRowsIndex(current.map((c) => c.dataIndex));
              }
            },
            selectToolbarPlacement: "none",
            rowsSelected: selectedRowsIndex,
          },
        }
      : {}),
  });

  if (isError) {
    console.error(error);
  }

  return (
    <Section isLoading={isLoadingCompanyConfigsQuery} label={props.label}>
      <Grid
        key={"contract-article-section"}
        container
        item
        alignItems="center"
        xs={12}
      >
        {!microsoftDriveClient?.rootDriveId ? (
          <MessageBase message="Drive access required" type="warning" />
        ) : formData && formData.length !== 0 ? (
          <MuiDataTableWrapper
            isFetching={isLoading}
            title="Offer documents"
            data={formData || []}
            columns={columns}
            options={options}
          />
        ) : (
          <>{getMissingDataMessage("documents", t)}</>
        )}
      </Grid>
      {!props.onlyTable ? (
        <Grid item xs={12} lg={12} sx={{ paddingTop: "2rem" }}>
          <ButtonGroup>
            {!microsoftDriveClient?.rootDriveId ? (
              <ButtonSecondary
                onClick={async () => {
                  await microsoftDriveClient?.initializeApp();
                  invalidate();
                }}
              >
                {t("Connect your Drive account")}
              </ButtonSecondary>
            ) : (
              <>
                <ButtonMicrosoftDriveFileUpload
                  collectionName={props.collectionName}
                  categories={{
                    group: groups?.length ? groups[0] : "",
                    type: types?.length ? types[0] : "",
                  }}
                  leafFolder={leafFolder}
                  invalidate={invalidate}
                  microsoftDriveClient={microsoftDriveClient}
                />
                {!!props?.showTemplateButton && (
                  <ButtonMicrosoftDriveCreateFileFromTemplate
                    allFiles={formData}
                    collectionName={props.collectionName}
                    dataPopulate={props?.dataPopulate}
                    leafFolder={leafFolder}
                    invalidate={invalidate}
                    prefixDocumentName={props.prefixDocumentName}
                  />
                )}
              </>
            )}
          </ButtonGroup>
          <PromptDialog
            title={"The file is locked"}
            message={
              "The file you are attempting to change is locked and can not be changed. Please close any open instances of the document and try again"
            }
            open={isMicrosoftDriveClientLocked}
            onClose={() => setIsMicrosoftDriveClientLocked(false)}
          />
        </Grid>
      ) : (
        <> </>
      )}
    </Section>
  );
};

export default MicrosoftDriveTable;
